<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="Name/GGID"
                  enter-button
                  allowClear
                  autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgThreeTwoList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgThreeTwoOps"
                  @change="orgThreeTwoChange('orgThreeTwo')"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFourOneOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgFourOneOneOps"
                  @change="orgThreeTwoChange('orgFourOneOne')"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Org5.1" name="orgFiveOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFiveOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgFiveOneOps"
                  @change="orgThreeTwoChange('orgFiveOne')"
              >
              </SelectWithAll>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button class="" type="primary" ghost @click="exportClick" v-auth="['system:cv:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {cloneDeep} from "lodash";
import { getValueAndOps } from "@/utils/orgSelectData";
import { ExportOutlined } from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
import {dictionaryOpt} from '@/utils/common'
export default defineComponent({
  name: "TopFilter",
  components: { ExportOutlined,Tooltip },
  emits: ["search", "export-click", "refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
      getOrgList();
    };
    const orgFiveOneOps = ref([])
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const formState = reactive({
      status: undefined,
      condition: "",
      orgFiveOneList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
    });
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: []
    });

    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList }  = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      })
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const {orgsValue, orgOpsList} = getValueAndOps(orgAllList.value,formOrgData,orgOpsObj,type)
      Object.assign(formState,orgsValue)
      Object.assign(orgOpsObj,orgOpsList)
    };
    let currentState = {};
    const updateParams =() => {
      currentState = cloneDeep(formState)
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    onMounted(() => {
      // nextTick(doSearch);
      getOrgList();
      getOrgRelatedList();
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      exportClick,
      orgOpsObj,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      updateParams,
      getOrgRelatedList,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
