<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
    />
    <CvList ref="cvListRef"></CvList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter";
import CvList from "./components/CvList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportCv",
  components: {
    CvList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const cvListRef = ref();
    const handleSearch = (searchParams) => {
      cvListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      cvListRef.value.doSearch();
    };

    const exportClick = (searchParams) => {
      $api.cvExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      cvListRef,
      handleSearch,
      handleResetTable,
      exportClick,
    };
  },
});
</script>
